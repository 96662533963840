









































































































































































import { getComponent } from '@/utils/helpers';

import { Component, Vue } from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';

@Component({
  components: {
    AuthLayout: () => getComponent('auth/AuthLayout'),
  },
})
export default class Login extends Vue {
  CODE_LENGTH = 6;

  dataLoading = false

  email = '';

  actionShown = 'login';

  code = ''

  errorMessage = '';

  loading = false;

  get verifyBtnDisabled() {
    return this.code == '';
  }

//   changeInputFocus(idx) {
//     if (this.code[idx] === '') {
//       const index = idx === 0 ? 0 : idx - 1;
//       this.$refs[`codeInput-${index}`][0].focus();
//       return;
//     }
//     if (this.code[idx] && idx === this.CODE_LENGTH - 1) {
//       this.$refs[`codeInput-${this.CODE_LENGTH - 1}`][0].blur();
//       return;
//     }
//     this.$nextTick(() => {
//       const index = idx === this.CODE_LENGTH - 1
//         ? this.CODE_LENGTH - 1 : idx + 1;
//       this.$refs[`codeInput-${index}`][0].focus();
//     });
//   }

  async login() {
    this.errorMessage = '';
    this.dataLoading = true;
    this.loading = true;
    try {
      await accountModule.login({ email: this.email });
      this.actionShown = 'verify';
      this.loading = false;
	  this.dataLoading = false;
    } catch (e) {
      if (e.message === 'User does not exist.') {
        e.message = 'Please check your email.';
      }
	  this.dataLoading = false;
    //   this.errorMessage = e.message;
	  this.errorMessage = "This email is not recognized by Tasq."
      this.loading = false;
    }
  }

  async verify() {
    this.loading = true;
    this.dataLoading = true;
    this.errorMessage = '';
    try {
      await accountModule.verify({ code: this.code });
      // accountModule.setUser()
        // console.log(accountModule.user.defaultPage)
      this.$router.push({ name: 'Home' });
      this.loading = false;
	  this.dataLoading = false;
    } catch (e) {
      e.message = 'Let’s try that again — check your code and make sure you enter the correct numbers.';
      this.errorMessage = e.message;
      this.loading = false;
	  this.dataLoading = false;
    }
  }
}
